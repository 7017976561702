@use "../global" as g;

// モックアップ
.mockup-list {
  &:not(:last-of-type) {
    margin-bottom: 60px;
  }
}

.mockup-item {
  margin-bottom: 10px;
  @include g.media(md) {
    margin-bottom: 30px;
  }
}


.logo-kaigo {
  img {
    max-width: 200px;
    @include g.media(md) {
      max-width: 280px;
    }
  }
}
.logo-smile, .logo-kizunadenwa, .logo-kizunaseikatsu {
  img {
    max-width: 280px;
    @include g.media(md) {
      max-width: 360px;
    }
  }
}

